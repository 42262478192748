.negativeChange {
  color: red;
}
.positiveChange {
  color: green;
}

.pagination__input__withPadding {
  input {
    padding-left: 8px !important;
  }
}
