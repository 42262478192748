* {
  padding: 0;
  margin: 0;
}

html {
  overflow-x: auto;
  /* scrollbar-width: 0px; */
  height: 100% !important;
}

html::-webkit-scrollbar {
  /* display: none !important; */
}

#root {
  height: 100% !important;
}
#heightControl {
  height: 100% !important;
}


@-moz-document url-prefix() {

  /* Disable scrollbar Firefox */
  /* html {
    scrollbar-width: none;
  } */
}

body {
  /* background: rgb(244, 245, 249); */
  margin: 0;
  /* remove default margin */
  /* scrollbar-width: none !important; */
  /* Also needed to disable scrollbar Firefox */
  /* -ms-overflow-style: none; */
  /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
  height: 100% !important;
}

/* body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

___SText_1t6wj_gg_ {
  overflow: visible;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 164px;
  -webkit-box-orient: vertical;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* recoloring active table item */
.___SRow_hqrkc_gg_.__active_hqrkc_gg_>.___SCell_hqrkc_gg_:not(.__theme_hqrkc_gg_) {
  background-color: #E0E1E9 !important;
}
/* aligning arrow to the top for the country select item */
.___SLinkAddon_1l2i5_gg_ {
  height: 18px !important;
}

.shadow-border {
  box-shadow: 0px 0px 1px rgba(25, 27, 35, 0.16), 0px 1px 2px rgba(25, 27, 35, 0.12) !important;
}

.InfoCardItem_max_number {
  right: 24.5%;
}
.InfoCardItem_max_number.additional_right {
  right: 22% !important;
}
.InfoCardItem_flex_wrapper {
  justify-content: space-between;
}

@media print {
  @page {
      size: auto;
      margin: 0mm;
    }

  body * {
    visibility: hidden;

  }

  @page:footer {
    display: none;

    margin-top: 0;
    margin-bottom: 0;
  }

  @page:header {
    display: none
  }
  @page {
    margin-top: 20px;
  }

    /* body {
      padding-top: 72px;
      padding-bottom: 72px;
    } */

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;

    width: calc(95% - 60px) !important;
    margin: 30px;

  }
  .InfoCardItem_max_number {
    right: 45%;
  }
  .InfoCardItem_flex_wrapper {
    padding-right: 24px;
  }
  .additional-pdf-info * {
    visibility: visible;
  }
  .no-pdf {
    display: none;
  }
  .full-width {
    width: 100% !important;
  }
  .full-width:first-of-type {
    padding-right: 6px !important;
    margin-right: 6px !important;
  }
  .full-width:last-of-type {
    padding-left: 6px !important;
    margin-left: 6px !important;
  }
  .print-card {
    page-break-inside: avoid;
  }
}

.arrowBox .___SLink_8k3ll_gg_:hover, .arrowBox .___SText_8k3ll_gg_ {
  text-decoration: none;
  color: transparent !important;
  border-color: transparent !important;
}

.arrowBox .___SLink_8k3ll_gg_.__disabled_8k3ll_gg_ {
  opacity: 0.2 !important;
}

.arrowWrapperLink {
  text-decoration: none;
  opacity: 0.5;
}
.arrowWrapperLink:hover {
  opacity: 1;
  /* color: none !important; */
}

.additional-pdf-info * {
  visibility: hidden;
}

.padding-holder {
  box-sizing: border-box;
  /* padding-top: 24px; */
  /* padding-bottom: 24px; */
  height: calc(100% - 24px);
  /* height: calc(100% - 24px - 2px) !important */
}

.control-line {
  padding-top: 24px;
}

/* TEMP */
.___SColumn_1cz0u_gg_.__sortable_1cz0u_gg_ {
  cursor: default !important;
}