.pillTab {
  border-radius: 0px !important;
  border-right: 0px;
}

.pill .pillTab:first-child {
  //   border-top-left-radius: 3px !important;
  border-radius: 3px 0px 0px 3px !important;
  //   border-bottom-left-radius: 3px !important;
  //   border-radius: 3px 3px 0 0 !important;
}

.pill .pillTab:last-child {
  //   border-top-right-radius: 3px !important;
  //   border-bottom-right-radius: 3px !important;
  //   border-right: 1px solid #a6b0b3 !important;
  border-radius: 0px 3px 3px 0px !important;
}
