.global_notice{
    border-radius: 6px;
    margin: 8px auto 0 auto;
    z-index: 9999;
    width: max-content;

    &_content{
        display: flex;
        justify-content: left;
    }

    &_text{
        max-width: 700px;
    }
}